/* styles.css */
.payment-form {
    display: flex;
    flex-direction: column;
  }
  
  .radio-group {
    display: flex;
    margin: 10px;
  }
  
  .radio-label {
    display: flex;
    align-items: center;
    margin-right: 20px;
    font-size: 16px;
    color: #333;
    position: relative;
  }
  
  input[type="radio"] {
    transform: scale(1.5);
    margin-right: 8px; 
  }
  
  .input-field {
    display: none;
    margin: 10px;
  }
  
  .show-input {
    display: block;
  }
  